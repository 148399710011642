import React from "react";
import { graphql } from 'gatsby';


import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

const NotFound = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "null", // cta, account, null
        }}
        footerConfig={{
          style: "style_snore"
        }}
      >
        <div className="inner-banner">
          <Container>
            <Row className="justify-content-center mt-md-6 pt-24 pt-lg-29">
              <Col lg="9" xl="8">
                <div className="px-md-12 text-center mb-11 mb-lg-14">
                  <h2 className="title gr-text-2 mb-9 mb-lg-12 heading-color">Oops! Not Found</h2>
                  <p className="gr-text-8 text-color-opacity mb-0 text-color-opacity">
                    The page you were looking for doesn’t exist. You may have mistyped the address or the page may have moved.
                  </p>
                </div>
              </Col>
              {/* <Col xs="12">
                <div className="banner-fluid-image pt-lg-9">
                  <img src={imgBanner} alt="" className="w-100" />
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        {/* <div className="about-content pt-lg-28 pt-13 pb-13 pb-lg-25">
          <Container>
            <Row>
              <Col lg="6" className="mb-7 mb-lg-0">
                <div className="pe-xl-13">
                  <h2 className="gr-text-3 heading-color mb-0">
                    We are here to help the customers to get their success.
                  </h2>
                </div>
              </Col>
              <Col lg="6">
                <div className="pe-xl-15">
                  <p className="gr-text-8 text-color-opacity mb-7 mb-lg-10">
                    We share common trends and strategies for improving your
                    rental income and making sure you stay in high demand of
                    service.{" "}
                  </p>
                  <p className="gr-text-8 text-color-opacity mb-0">
                    With lots of unique blocks, you can easily build a page
                    without coding. Build your next landing page. With lots of
                    unique blocks, you can easily build a page without coding
                    any other page.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
      </PageWrapper>
    </>
  );
};
export default NotFound;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
